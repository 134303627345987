import React from 'react';
import {
	ContactWrapperStyled,
	IconTeaserStyled,
	IconTeaserTextStyled,
	ImageStyled,
	HeadlineStyled,
	SectionStyled,
} from './styles';
import Column from '../../Layout/Grid/Column';
import Row from '../../Layout/Grid/Row';
import IcoCallback from '../../../assets/img/icons/360-grad/ico-callback.svg';
import IcoCleo from '../../../assets/img/icons/360-grad/ico-app-chat.svg';
import IcoMail from '../../../assets/img/icons/360-grad/ico-mail.svg';
import IcoInternational from '../../../assets/img/icons/360-grad/ico-international.svg';

export const Contact = () => {
	const handleMailClick = () => {
		window.dataLayer.push({
			event: 'biib_internalLinkClick',
			eventAsset: 'click on write an email at the bottom of the page',
			eventAssetBis: 'info@ms-service-center.de',
		});
	};
	return (
		<ContactWrapperStyled>
			<HeadlineStyled as="h2">So kannst du uns erreichen!</HeadlineStyled>
			<Row>
				<Column sm={6} lg={3}>
					<IconTeaserStyled to="/service/service-team/">
						<ImageStyled src={IcoCallback} />
						<IconTeaserTextStyled>MS Service-Center anrufen</IconTeaserTextStyled>
					</IconTeaserStyled>
				</Column>
				<Column sm={6} lg={3}>
					<IconTeaserStyled to="mailto:info@ms-service-center.de" onClick={handleMailClick}>
						<ImageStyled src={IcoMail} />
						<IconTeaserTextStyled>E-Mail an das MS Service-Center verfassen</IconTeaserTextStyled>
					</IconTeaserStyled>
				</Column>
				<Column sm={6} lg={3}>
					<IconTeaserStyled to="https://www.cleo-app.de/">
						<ImageStyled src={IcoCleo} />
						<IconTeaserTextStyled>Cleo App benutzen</IconTeaserTextStyled>
					</IconTeaserStyled>
				</Column>
				<Column sm={6} lg={3}>
					<IconTeaserStyled to="/service/ms-behandlung#international">
						<ImageStyled src={IcoInternational} />
						<IconTeaserTextStyled>International</IconTeaserTextStyled>
					</IconTeaserStyled>
				</Column>
			</Row>
		</ContactWrapperStyled>
	);
};

const ContactSection = () => {
	return (
		<SectionStyled showPadding center>
			<Contact />
		</SectionStyled>
	);
};

/** @component */
export default ContactSection;
