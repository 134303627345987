// helper function
export const convertMillisecondsToSecondsAsString = ms => `${ms / 1000}s`;

/** create an object with sub-paths 'milliseconds' and (css-)'strings',
 * based on key/millisecond-values passed in 'prototypeMilliseconds',
 * for usage in animated component, where both css-strings and milliseconds/numbers
 * are needed
 */
export const createDurationsObject = prototypeMilliseconds => {
	const milliseconds = {
		...prototypeMilliseconds
	};

	// make an object of strings, like {complete: '1.2s', fading: '0.5s'}
	// for usage in css styles
	const strings = Object.entries(milliseconds).reduce(
		(result, [key, value]) => ({
			...result,
			[key]: convertMillisecondsToSecondsAsString(value)
		}),
		{}
	);

	return {
		milliseconds,
		strings
	};
};
