import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Spring} from 'react-spring/renderprops';
import * as Actions from '../../../../state/actions/default';
import {ActionBarStyled, ActionFavoriteStyled, IconStyled, FavoritesStyled} from './styles';
import ProgressCircle from '../../../Elements/ProgressCircle/ProgressCircle';
import {createShapeOfLikes, createShapeOfBookmarks} from './definitions';

class ArticleActions extends Component {
	state = {toggleLike: false, toggleBookmark: false, visibility: false, dur: 800, animTo: true};

	clickLike = e => {
		e.preventDefault();
		const {
			actions: {postLike, postUnlike},
			likes,
			title,
			slug,
			label,
			wordpressId
		} = this.props;
		if (likes.likeBeingProcessed) {
			return;
		}
		const likeFiltered = likes.results.find(elem => elem.post_id === wordpressId);
		likeFiltered.liked === true ? postUnlike(wordpressId) : postLike(wordpressId);
		if (likeFiltered.liked === true) {
			window.dataLayer.push({
				event: 'biib_removeFavorites',
				biib_feedContent: title,
				biib_feedContentCat: label,
				biib_contentUrl: slug
			});
		} else {
			window.dataLayer.push({
				event: 'biib_addFavorites',
				biib_feedContent: title,
				biib_feedContentCat: label,
				biib_contentUrl: slug
			});
		}
		this.setState({toggleLike: true});
	};

	clickBookmark = e => {
		e.preventDefault();
		const {
			actions: {postBookmark, postUnbookmark, notifyUser},
			wordpressId,
			bookmarks,
			title,
			slug,
			label,
			user
		} = this.props;
		if (user) {
			if (bookmarks.bookmarkBeingProcessed) {
				return;
			}
			const currentUser = parseInt(user.UserId, 10);

			if (bookmarks.results) {
				const bookmarkFiltered = bookmarks.results.filter(elem => parseInt(elem.post_id, 10) === wordpressId);
				if (bookmarkFiltered.length > 0) {
					window.dataLayer.push({
						event: 'biib_removeBookmarks',
						biib_feedContent: title,
						biib_feedContentCat: label,
						biib_contentUrl: slug
					});
					postUnbookmark(currentUser, wordpressId);
				} else {
					window.dataLayer.push({
						event: 'biib_addBookmarks',
						biib_feedContent: title,
						biib_feedContentCat: label,
						biib_contentUrl: slug
					});
					postBookmark(currentUser, wordpressId);
				}
			} else {
				window.dataLayer.push({
					event: 'biib_addBookmarks',
					biib_feedContent: title,
					biib_feedContentCat: label,
					biib_contentUrl: slug
				});
				postBookmark(currentUser, wordpressId);
			}
			this.setState({toggleBookmark: true});
		} else {
			const error = {text: 'Bitte logge dich ein, um die Lesezeichen Funktion nutzen zu können.', button: 'OK'};
			notifyUser(error);
		}
	};

	stopAnimation = () => {
		const {animTo} = this.state;
		if (animTo === true) {
			this.setState({visibility: false, dur: 0, animTo: false, toggleLike: false, toggleBookmark: false});
		} else {
			this.setState({visibility: true, dur: 800, animTo: true});
		}
	};

	render() {
		const {className, likes, bookmarks, wordpressId, isSearchResult, user, slug} = this.props;
		const {toggleLike, toggleBookmark, visibility, dur} = this.state;
		const self = this;
		let isBookmarked = false;
		let isLiked = false;
		const hideOnSpecials = slug.includes('/multiple-sklerose')
		if (!likes || !likes.results || likes.results.length === 0 || isSearchResult) {
			return null;
		}

		const likeFiltered = likes.results.find(elem => elem.post_id === wordpressId);
		if (likeFiltered && likeFiltered.liked) {
			({liked: isLiked} = likeFiltered);
		}

		if (user && bookmarks.results) {
			const bookmarkFiltered = bookmarks.results.find(elem => parseInt(elem.post_id, 10) === wordpressId);

			if (!!bookmarkFiltered && wordpressId === parseInt(bookmarkFiltered.post_id, 10)) {
				isBookmarked = true;
			}
		}

		const likesText = likeFiltered && likeFiltered.likes === 1 ? 'Like' : 'Likes';
		
		return (
			<ActionBarStyled className={className} hide={hideOnSpecials}>
				<Spring
					onRest={self.stopAnimation}
					config={{duration: dur}}
					toggle={this.toggleLike}
					from={{value: 0}}
					to={{value: toggleLike ? 100 : 0}}>
					{props => (
						<ActionFavoriteStyled liked={isLiked} onClick={this.clickLike}>
							<ProgressCircle
								strokeWidth="2"
								sqSize="50"
								visibility={visibility}
								percentage={props.value}>
								<IconStyled name="like" />
							</ProgressCircle>
						</ActionFavoriteStyled>
					)}
				</Spring>
				<FavoritesStyled>
					{likeFiltered ? likeFiltered.likes : `0`} {likesText}
				</FavoritesStyled>
				<Spring
					onRest={self.stopAnimation}
					config={{duration: dur}}
					toggle={this.toggleBookmark}
					from={{value: 0}}
					to={{value: toggleBookmark ? 100 : 0}}>
					{props => (
						<ActionFavoriteStyled liked={isBookmarked} onClick={this.clickBookmark}>
							<ProgressCircle
								strokeWidth="2"
								sqSize="50"
								visibility={visibility}
								percentage={props.value}>
								<IconStyled name="bookmark" />
							</ProgressCircle>
						</ActionFavoriteStyled>
					)}
				</Spring>
			</ActionBarStyled>
		);
	}
}

const mapStateToProps = state => {
	return {
		likes: state.likes,
		bookmarks: state.bookmarks,
		user: state.userManagement.user
	};
};

const mapDispatchToProps = dispatch => {
	return {actions: bindActionCreators(Actions, dispatch)};
};

ArticleActions.propTypes = {
	className: PropTypes.string,
	wordpressId: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	slug: PropTypes.string.isRequired,
	label: PropTypes.string,
	isSearchResult: PropTypes.bool,
	likes: createShapeOfLikes().isRequired,
	bookmarks: createShapeOfBookmarks().isRequired,
	actions: PropTypes.objectOf(PropTypes.func).isRequired,
	user: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ArticleActions.defaultProps = {
	className: 'ap-label',
	label: '',
	isSearchResult: true,
	user: null
};

/** @component */
export default connect(mapStateToProps, mapDispatchToProps)(ArticleActions);
