import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {StyledDiv} from './styles';

/**
 * Shows an effect when the prop 'propToObserve' changes, so it is not necessary to send an action
 * (or do something else) to trigger the effect.
 * It sets a css value, and removes it after @duration.
 *
 * @propToObserve:      any      one of the children's props, when this prop changes, the effect gets triggered
 * @duration:           number   duration of the effect in milliseconds
 * @offset:             number   delay in milliseconds, after which the content is changed
 *
 * todo: pass different effect types as prop, in the form of css style rules (a styled-component css`...` object)
 */
export default class ChangeEffect extends React.PureComponent {
	static propTypes = {
		propToObserve: PropTypes.arrayOf(PropTypes.string),
		duration: PropTypes.number,
		children: PropTypes.element
	};

	static defaultProps = {
		duration: 600,
		propToObserve: null,
		children: null
	};

	state = {
		withEffect: false
	};

	componentDidUpdate(prevProps) {
		const {propToObserve: prevPropToObserve} = prevProps;
		const {duration, propToObserve: thisPropToObserve} = this.props;

		if (!_.isEqual(thisPropToObserve, prevPropToObserve)) {
			this.addEffect();
			setTimeout(this.removeEffect, duration);
		}
	}

	addEffect = () => {
		this.setState({withEffect: true});
	};

	removeEffect = () => {
		this.setState({withEffect: false});
	};

	render() {
		const {withEffect} = this.state;
		const {children} = this.props;
		return <StyledDiv withEffect={withEffect}>{children}</StyledDiv>;
	}
}
