import styled, {css} from 'styled-components';
import {HeadlineStyledH5} from '../../../Elements/Headline/styles';
import {colors, sizes} from '../../../../styles/variables';
import {withUnit, media} from '../../../../styles/utils';
import Label from '../../../Elements/Label/Label';
import Image from '../../../Elements/Image/Image';
import ArticleActions from '../ArticleActions/ArticleActions';

export const ShortTeaserStyled = styled.div`
	${media.md`
		padding: ${withUnit(sizes.distance.base / 4)};
	`};

	${media.xl`
		padding: ${withUnit(sizes.distance.base / 2)};
	`};

	color: ${colors.black}!important;
	&:hover {
		box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
	}
`;

export const TeaserImageStyled = styled(Image)`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	transition: transform 1s ease;

	transform: translate(-50%, -50%) scale(1.2) rotate(2deg);
	transform-origin: 50% 50%;
`;

const TeaserFullImageWrapperStyles = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
`;

export const TeaserImageWrapperStyled = styled.div`
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
	flex: 1 1 auto;
	${(props) => (props.appearance === 'full-image' ? TeaserFullImageWrapperStyles : ``)};
`;

export const TeaserContentStyled = styled.div`
	box-sizing: border-box;
	padding: 1em 1em 70px 1em;
	overflow: hidden;
	flex: none;
	height: 60%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	> * {
		flex: 1 1 auto;
	}
`;

export const TeaserLabelStyled = styled(Label)`
	flex: 0 1 auto;
	${(props) => (props.appearance === 'full-image' ? `color: ${colors.white};text-shadow: 2px 2px 8px black;` : ``)};
	font-size: 0.8em;

	&::after {
		transition: width 0.5s ease, background 0.5s ease;
	}
`;

export const TeaserStyled = styled.div`
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	box-sizing: border-box;

	background-color: ${colors.white};
	text-decoration: none;
	color: ${colors.black};
	text-align: left;
	height: 500px;

	${media.lg`
		height: 600px;
	`};

	&:hover {
		${TeaserImageStyled} {
			transform: translate(-50%, -50%) scale(1) rotate(0deg);
		}
	}

	.ap-search-postlist & {
		box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
	}
`;

export const TeaserTitleStyled = styled(HeadlineStyledH5)`
	flex: 0 1 auto;
	${(props) => (props.appearance === 'full-image' ? `color: ${colors.white};text-shadow: 2px 2px 8px black;` : ``)};

	// sadly have to boost specificity :(
	&& {
		margin-bottom: 0;
	}
`;

export const TeaserTextStyled = styled.div`
	position: relative;
	overflow: visible;
	margin-top: ${withUnit(sizes.distance.base / 2)};
	font-size: 0.9em;

	// kk: important to overwrite font-weight of anchors
	font-weight: 300;
	${(props) => (props.appearance === 'full-image' ? `color: ${colors.white};text-shadow: 2px 2px 8px black;` : ``)};
`;

export const TeaserActionsStyled = styled(ArticleActions)`
	flex: none;
	${(props) => (props.appearance === 'full-image' ? `color: ${colors.white};` : ``)};

	height: 70px;
	justify-self: flex-end;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 1em;
	box-sizing: border-box;
`;
