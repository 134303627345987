import styled from 'styled-components';
import {colors, sizes} from '../../../styles/variables';
import Link from '../../Elements/Link/Link';
import Headline from '../../Elements/Headline/Headline';
import {orangeBorder, withUnit} from '../../../styles/utils';
import Section from '../../Layout/Section/Section';

export const ContactWrapperStyled = styled.div`
	max-width: 1000px;
	margin: 0 auto;
`;

export const IconTeaserStyled = styled(Link)`
	max-width: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;

	&:hover,
	&:active,
	&:focus {
		img {
			transform: translateY(-3px);
		}
	}
`;

export const HeadlineStyled = styled(Headline)`
	position: relative;
	padding-bottom: ${withUnit(sizes.distance.base)};
	${orangeBorder()};
	margin-top: 0;
`;

export const SectionStyled = styled(Section)`
	border-top: 2px solid ${colors.snow};

	h2::after {
		left: 50%;
		transform: translateX(-50%);
	}
`;

export const ImageStyled = styled.img`
	width: 150px;
	height: 150px;
	transition: transform 0.3s ease-out;
`;

export const IconTeaserTextStyled = styled.div`
	text-align: center;
	line-height: 1.2;
	font-weight: 800;
	font-size: 0.8em;
	color: ${colors.black};
`;
