import PropTypes from 'prop-types';

export const createShapeOfLike = () =>
	PropTypes.shape({
		post_id: PropTypes.number,
		likes: PropTypes.number,
		liked: PropTypes.bool
	});

export const createShapeOfLikes = () =>
	PropTypes.shape({
		articleLike: PropTypes.number,
		counter: PropTypes.number,
		likesUpdate: PropTypes.bool,
		loading: PropTypes.bool,
		results: PropTypes.arrayOf(createShapeOfLike()),
		updatedCounter: PropTypes.number
	});

export const createShapeOfBookmark = () =>
	PropTypes.shape({
		post_id: PropTypes.string,
		bookmarked: PropTypes.bool
	});

export const createShapeOfBookmarks = () =>
	PropTypes.shape({
		articleBookmark: PropTypes.number,
		bookmarksUpdate: PropTypes.bool,
		loading: PropTypes.bool,
		results: PropTypes.arrayOf(createShapeOfBookmark())
	});
