import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import {connect} from 'react-redux';
import Layout from '../components/Layout/Layout';
import Section from '../components/Layout/Section/Section';
import {BlogTemplateStyled} from './styles';
import Meta from '../components/Layout/Meta';
import {DossierListContainerWithChangeEffect as DossierList} from '../components/Container/Stream/DossierList/Container';
import ReleaseNumber from '../components/Elements/ReleaseNumber/ReleaseNumber';
import ContactSection from '../components/Content/ContactSection/ContactSection';

const IndexPage = ({data, location, showPersonal}) => {
	const {edges: dossiers} = data.allWordpressWpNews;
	const {wordpressPage: page} = data;

	return (
		<>
			<Meta />
			<Layout location={location}>
				<BlogTemplateStyled>
					<Section showPadding theme="grey">
						<DossierList posts={dossiers} title="News und Specials" section="dossiersList" />

						<ReleaseNumber releaseNumber={page.acf.release_number} />
					</Section>
				</BlogTemplateStyled>

				<ContactSection />
			</Layout>
		</>
	);
};

const mapStateToProps = (state) => ({
	showPersonal: state.userManagement.showPersonal,
});

IndexPage.propTypes = {
	data: PropTypes.objectOf(PropTypes.object).isRequired,
	pageContext: PropTypes.shape({
		currentPage: PropTypes.number,
		numPages: PropTypes.number,
	}),
	location: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
	showPersonal: PropTypes.string,
};

IndexPage.defaultProps = {
	pageContext: null,
	showPersonal: null,
};

export default connect(mapStateToProps)(IndexPage);

export const pageQuery = graphql`
	query DossierById($id: String!) {
		wordpressPage(id: {eq: $id}) {
			acf {
				release_number
			}
		}
		allWordpressWpNews(sort: {fields: [date], order: DESC}, filter: {acf: {hide_in_flow: {eq: null}}}) {
			edges {
				node {
					id
					wordpress_id
					title
					excerpt
					acf {
						hidden
						hide_in_flow
					}
					tags {
						name
					}
					date(formatString: "MMMM DD, YYYY")
					slug
					path
					featured_media {
						localFile {
							childImageSharp {
								id
								fluid(srcSetBreakpoints: [800, 250], quality: 80) {
									...GatsbyImageSharpFluid_noBase64
								}
							}
						}
					}
				}
			}
		}
	}
`;
